<template>
  <div>
    <template v-if="!submitted">
      <h1>
        Fuldfør din registrering
      </h1>
      <p class="subheading">
        Velkommen{{ register.firstName ? ` ${register.firstName}` : '' }}. For at fuldgøre din registrering har vi brug for nogle personoplysninger nedenfor.
      </p>
      <form @submit.prevent="processForm">
        <BaseInput
          name="registrationCode"
          v-model="register.registrationCode"
          label="Registreringskode*"
          placeholder="Registreringskode"
          :hasInput="!!v$.register.registrationCode.$model"
          :isValid="!v$.register.registrationCode.$invalid"
          :error="v$.register.registrationCode.$error ? v$.register.registrationCode.$errors[0].$message : ''"
          :disabled="registrationCodeIsValid"
          @blur="validateRegistrationCode"
        />
        <BaseInput
          name="firstName"
          v-model="register.firstName"
          label="Fornavn*"
          placeholder="Fornavn"
          :hasInput="!!v$.register.firstName.$model"
          :isValid="!v$.register.firstName.$invalid"
          :error="v$.register.firstName.$error ? v$.register.firstName.$errors[0].$message : ''"
          :disabled="!registrationCodeIsValid"
          @blur="v$.register.firstName.$touch()"
        />
        <BaseInput
          name="lastName"
          v-model="register.lastName"
          label="Efternavn*"
          placeholder="Efternavn"
          :hasInput="!!v$.register.lastName.$model"
          :isValid="!v$.register.lastName.$invalid"
          :error="v$.register.lastName.$error ? v$.register.lastName.$errors[0].$message : ''"
          :disabled="!registrationCodeIsValid"
          @blur="v$.register.lastName.$touch()"
        />
        <BaseInput
          name="email"
          v-model="register.email"
          label="E-mail*"
          placeholder="E-mail"
          :hasInput="!!v$.register.email.$model"
          :isValid="!v$.register.email.$invalid"
          :error="v$.register.email.$error ? v$.register.email.$errors[0].$message : ''"
          :disabled="!registrationCodeIsValid"
          @blur="v$.register.email.$touch()"
        />
        <BaseInput
          name="mobilePhone"
          v-model="register.mobilePhone"
          label="Telefonnummer*"
          placeholder="Telefonnummer"
          :hasInput="!!v$.register.mobilePhone.$model"
          :isValid="!v$.register.mobilePhone.$invalid"
          :error="v$.register.mobilePhone.$error ? v$.register.mobilePhone.$errors[0].$message : ''"
          :disabled="!registrationCodeIsValid"
          @blur="v$.register.mobilePhone.$touch()"
        />
        <BaseInput
          name="password"
          type="password"
          v-model="register.password"
          label="Adgangskode*"
          placeholder="••••••••••••"
          :hasInput="!!v$.register.password.$model"
          :isValid="!v$.register.password.$invalid"
          :error="v$.register.password.$error ? v$.register.password.$errors[0].$message : ''"
          :error-additional="passwordErrorAdditional"
          @blur="v$.register.password.$touch()"
        />
        <BaseInput
          name="confirmPassword"
          type="password"
          v-model="register.confirmPassword"
          label="Gentag adgangskoden*"
          placeholder="••••••••••••"
          :hasInput="!!v$.register.confirmPassword.$model"
          :isValid="!v$.register.confirmPassword.$invalid"
          :error="v$.register.confirmPassword.$error ? v$.register.confirmPassword.$errors[0].$message : ''"
          @blur="v$.register.confirmPassword.$touch()"
        />
        <BaseCheckbox
          name="acceptTerms"
          v-model="acceptTerms"
          label="Jeg accepterer"
          labelLinkText="vilkårene"
          labelLinkUrl="/pdfs/skimsafe_terms_and_conditions.pdf"
          :isValid="!v$.acceptTerms.$invalid"
          :error="v$.acceptTerms.$error ? v$.acceptTerms.$errors[0].$message : ''"
          :disabled="!registrationCodeIsValid"
          @blur="v$.acceptTerms.$touch()"
        />
        <button type="submit" class="button-primary">
          Tilmeld
        </button>
      </form>
    </template>
    <div v-else-if="loading" class="flex-grow flex items-center justify-center">
      Loader ...
      <Spinner class="ml-3" />
    </div>
    <template v-else-if="success">
      <RegistrationSuccess />
      <h1>
        Din registrering er færdig
      </h1>
      <p class="subheading">
        Log ind automatisk. Hvis der ikke sker noget, så klik på knappen nedenfor.
      </p>
      <a :href="portalUrl" class="button-primary">
        Gå til portalen ({{ redirectSeconds }})
      </a>
    </template>
    <div v-else class="card card-error">
      <h6 class="">
        Noget gik galt med din registrering
      </h6>
      <p>
        Genindlæs siden og prøv igen. Hvis der stadig er problemer, så kontakt vores kundeservice på hackingalarm@skimsafe.dk
      </p>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput'
import BaseCheckbox from '@/components/BaseCheckbox'
import RegistrationSuccess from '@/components/RegistrationSuccess'
import Spinner from '@/components/Spinner'
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs, email, helpers } from '@vuelidate/validators'
import ky from 'ky'

// const registrationCodeValidation = (value, siblings, vm) => siblings.registrationCodeIsValid
const pwdMinChars = 8
const customPasswordValidation = (value) => /[A-Z]/.test(value) && /[a-z]/.test(value) && /[^0-9a-zÀ-ž\s]/i.test(value)

export default {
  name: 'Register',
  setup () {
    return { v$: useVuelidate() }
  },
  components: {
    BaseInput,
    BaseCheckbox,
    RegistrationSuccess,
    Spinner
  },
  data () {
    return {
      registrationCodeIsValid: true,
      register: {
        registrationCode: this.$route.query.registrationCode || '',
        email: '',
        firstName: '',
        lastName: '',
        mobilePhone: '',
        password: '',
        confirmPassword: ''
      },
      acceptTerms: false,
      loading: false,
      error: '',
      submitted: false,
      success: true,
      portalUrl: '',
      redirectSeconds: 3
    }
  },
  computed: {
    passwordErrorAdditional () {
      return this.v$.register.password.$error && this.v$.register.password.$errors[0].$validator === 'custom'
        ? 'Adgangskoden skal indeholde mindst et specialtegn, et stort bogstav og et lille bogstav.'
        : ''
    }
  },
  validations () {
    return {
      register: {
        registrationCode: {
          required: helpers.withMessage('Registreringskode er påkrævet', required),
          custom: helpers.withMessage('Gyldig registreringskode er påkrævet', async (value) => {
            if (value === '') return true
            const validateResponse = await ky.get(`/api/registration?registrationCode=${this.register.registrationCode}`).json()
            return validateResponse.success
          })
        },
        email: {
          required: helpers.withMessage('E-mail er påkrævet', required),
          email: helpers.withMessage('E-mailadressen skal være gyldigt', email)
        },
        firstName: {
          required: helpers.withMessage('Fornavn er påkrævet', required)
        },
        lastName: {
          required: helpers.withMessage('Efternavn er påkrævet', required)
        },
        mobilePhone: {
          required: helpers.withMessage('Telefonnummer er påkrævet', required)
        },
        password: {
          required: helpers.withMessage('Adgangskode er påkrævet', required),
          minLength: helpers.withMessage(`Adgangskoden skal være på mindst ${pwdMinChars} tegn`, minLength(pwdMinChars)),
          custom: helpers.withMessage('Adgangskoden skal være kompleks', customPasswordValidation)
        },
        confirmPassword: {
          required: helpers.withMessage('Gentag adgangskoden er påkrævet', required),
          sameAsPassword: helpers.withMessage('Adgangskoderne skal være ens', sameAs(this.register.password))
        }
      },
      acceptTerms: {
        isTrue: helpers.withMessage('Vilkår skal accepteres', sameAs(true))
      }
    }
  },
  mounted () {
    this.validateRegistrationCode()
  },
  methods: {
    async validateRegistrationCode () {
      if (this.register.registrationCode) {
        try {
          const validateResponse = await ky.get(`/api/registration?registrationCode=${this.register.registrationCode}`).json()
          console.log(validateResponse)
          console.log(validateResponse.success)
          this.registrationCodeIsValid = validateResponse.success
          console.log(this.registrationCodeIsValid)
          this.v$.register.registrationCode.$touch()
          if (this.registrationCodeIsValid) {
            this.register = {
              ...this.register,
              ...validateResponse.user
            }
          } else {
            if (validateResponse.login) {
              // redirect to login screen
              this.$router.push({ name: 'Login' })
            }
          }
        } catch (error) {
          // console.log(error)
          this.registrationCodeIsValid = false
        }
      }
    },
    async processForm () {
      this.v$.$touch()
      // console.log(this.v$.$invalid)
      if (!this.v$.$invalid) {
        this.loading = true
        this.submitted = true
        try {
          const registerResponse = await ky.post('/api/register', {
            json: {
              ...this.register
            }
          }).json()
          // console.log(registerResponse)
          this.success = registerResponse.success
          this.loading = false
          if (this.success) {
            // register successfull
            this.redirectSeconds = 3
            this.portalUrl = registerResponse.url
            while (this.redirectSeconds > 0) {
              await new Promise(resolve => setTimeout(resolve, 1000))
              this.redirectSeconds -= 1
            }
            // console.log('redirect to ', this.portalUrl)
            window.location = this.portalUrl
          }
        } catch (error) {
          // console.log(error)
          this.success = false
          this.loading = false
        }
      }
    }
  }
}
</script>
